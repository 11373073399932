import React, {useState} from "react";
import * as style from "./herbBottleDetails.module.scss";
import {fetchAcu} from "../../fetchacu";
import Select from "react-select";

const HerbBottleDetails = (props) => {

    // supplier_id, supplier_name, herb_code, herb_name
    // const [lot, setLot] = useState(props.bottle.lot)
    const [currentWeight, setCurrentWeight] = useState(props.bottle.current_weight)
    // const [initialWeight, setInitialWeight] = useState(props.bottle.initial_weight)
    // const [bottleCost, setBottleCost] = useState(props.bottle.bottle_cost)
    const [notes, setNotes] = useState(props.bottle.notes)
    // const [supplier, setSupplier] = useState({ value: props.bottle.supplier_id, label: props.bottle.supplier_name })
    const [location, setLocation] = useState({ value: props.bottle.location, label: props.bottle.location })
    // const [suppliers, setSuppliers] = React.useState([]);

    // React.useEffect(() => {
    //     fetchAcu('stock/suppliers')
    //         .then(
    //             response => {
    //                 if ( response.status === 200 )
    //                     response.json().then(
    //                         data => {
    //                             setSuppliers(data);
    //                         }
    //                     )
    //                 else {
    //                     response.text().then(
    //                         errorMessage => {
    //                             console.log(errorMessage)
    //                             // setError({status: response.status, message: errorMessage})
    //                         }
    //                     )
    //                 }
    //             }
    //         )
    //         .catch(err => console.log(err))
    // }, []);

    const handleSubmit = async () => {
        //e.preventDefault()
        let bottle = {
            lot: '', //lot,
            notes: notes,
            current_weight: parseFloat(currentWeight),
            initial_weight: 0, // parseFloat(initialWeight),
            supplier_id: 1, //parseInt(supplier.value),
            location: location.value,
            herb_code: props.bottle.herb_code,
            bottle_cost: 0, // parseFloat(bottleCost),
        }
        if (props.bottle.bottle_id) { // edit
            let response = await fetchAcu('stock/bottle/' + props.bottle.bottle_id, {method: 'PUT', body: JSON.stringify(bottle)})
            if (response.status === 200) {
                const okMessage = await response.text()
                console.log("Update bottle: " + okMessage)
                bottle.bottle_id = props.bottle.bottle_id;
                // bottle.supplier_name = supplier.label
                bottle.location = location.value
                props.onHerbBottleEdit(bottle)
            } else {
                const errorMessage = await response.text()
                console.log("Update bottle error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        } else { // create
            let response = await fetchAcu('stock/bottle', {method: 'POST', body: JSON.stringify(bottle) })
            if (response.status === 200) {
                const data = await response.json()
                console.log("Create bottle: " + data)
                bottle.bottle_id = data.bottle_id;
                // bottle.supplier_name = supplier.label
                bottle.location = location.value
                props.onHerbBottleAdd(bottle)
            } else {
                const errorMessage = await response.text()
                console.log("Create bottle error: " + errorMessage)
                props.onError({status: response.status, message: errorMessage})
            }
        }
    }

    return (
        <form onSubmit={(event)  =>{  event.preventDefault(); handleSubmit().then() } }>
        <div className={style.herbBottlePad}>
            <div className={style.herbBottle}>
                <div className={style.herbBottleTitleBar}>{
                    props.bottle.bottle_id
                        ? 'Edit the bottle of '  + props.bottle.herb_name
                        :'Add a bottle of ' + props.bottle.herb_name
                }</div>
                <div className={style.herbBottleContent}>
                    <div className={style.herbBottleRow}>
                        <label htmlFor={'selLocation'}>Location:</label>
                        <Select id={'selLocation'} defaultValue={
                            props.bottle.location ? {
                                value: props.bottle.location,
                                label: props.bottle.location
                            } : null}
                                options={[
                                    {value: 'Brookline', label: 'Brookline'},
                                    {value: 'Dedham', label: 'Dedham'},
                                ]}
                                onChange={(v) => setLocation(v)}
                        />
                    </div>
                    {/*<div className={style.herbBottleRow}>*/}
                    {/*    <label htmlFor={'supplier'}>Supplier:</label>*/}
                    {/*    <Select defaultValue={*/}
                    {/*        props.bottle.supplier_id ? {*/}
                    {/*            value: props.bottle.supplier_id,*/}
                    {/*            label: props.bottle.supplier_name*/}
                    {/*        } : null}*/}
                    {/*            options={[*/}
                    {/*                ...suppliers.map(s => ({value: s.supplier_id, label: s.supplier_name}))*/}
                    {/*            ]}*/}
                    {/*            onChange={(v) => setSupplier(v)}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div className={style.herbBottleRow}>*/}
                    {/*    <label htmlFor={'lot'}>Lot:</label>*/}
                    {/*    <input type={'text'} id={'lot'} defaultValue={props.bottle.lot}*/}
                    {/*           onChange={(e) => setLot(e.target.value)}/>*/}
                    {/*</div>*/}
                    {/*<div className={style.herbBottleRow}>*/}
                    {/*    <label htmlFor={'bottle-cost'}>Bottle cost:</label>*/}
                    {/*    <input type={'number'} step={0.01} id={'bottle-cost'} required={true}*/}
                    {/*           defaultValue={props.bottle.bottle_cost}*/}
                    {/*           onChange={(e) => setBottleCost(e.target.value)}/>*/}
                    {/*</div>*/}
                    {/*<div className={style.herbBottleRow}>*/}
                    {/*    <label htmlFor={'initial-weight'}>Initial weight (g.):</label>*/}
                    {/*    <input type={'number'} step={1} id={'initial-weight'} required={true}*/}
                    {/*           defaultValue={props.bottle.initial_weight}*/}
                    {/*           onChange={(e) => setInitialWeight(e.target.value)}/>*/}
                    {/*</div>*/}
                    <div className={style.herbBottleRow}>
                        <label htmlFor={'current-weight'}>Current weight (g.):</label>
                        <input type={'number'} step={0.01} id={'current-weight'} required={true}
                               defaultValue={props.bottle.current_weight}
                               onChange={(e) => setCurrentWeight(e.target.value)}/>
                    </div>
                    <div className={style.herbBottleRow}>
                        <label htmlFor={'notes'}>Notes:</label>
                        <textarea id={'notes'}
                                  defaultValue={props.bottle.notes}
                                  onChange={(e) => setNotes(e.target.value)}></textarea>
                    </div>
                    <div className={style.herbBottleButtonsRow}>
                        <input className={style.herbBottleButton} type={'submit'}
                               value={props.bottle.bottle_id ? "Save" : "Submit"}/>
                        <button className={style.herbBottleButton} onClick={(e) => {
                            e.preventDefault();
                            props.onCancel();
                            return false
                        }}>Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </form>
    )
}

export default HerbBottleDetails