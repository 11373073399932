import React, {useEffect, useState} from "react";
import * as style from "./newPrescription.module.scss"
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../FixRequiredSelect";
// import Select from "react-select";
import {fetchAcu} from "../../fetchacu";
import {caseInsensitiveSort} from "../../data-table-utils";

const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
    />
);

const consultFeeOptions = [{label: '$0', value: 0}, {label: '$15', value: 15}, {label: '$30', value: 30}]

const NewPrescription = (props) => {
    const renew = props.renewPrescription || {}
    const [takeDays, setTakeDays] = useState(renew.take_days)
    const [takeTimesPerDay, setTakeTimesPerDay] = useState(renew.take_times_per_day)
    const [takeGrams, setTakeGrams] = useState(renew.take_grams)
    const [formulaId, setFormulaId] = useState(renew.formula_id)
    const [formulaWeight, setFormulaWeight] = useState()
    const [formulaCost, setFormulaCost] = useState()
    const [formulaName, setFormulaName] = useState()
    const [paymentMethod, setPaymentMethod] = useState()
    const [consultFee, setConsultFee] = useState(0)
    const consultFeeOptionSelected = consultFeeOptions.find(o => (o.value === consultFee))
    // const [deliveryMethod, setDeliveryMethod] = useState()
    // const deliveryCost = deliveryMethod === "USPS" ? 9 : 0;
    const totalGrams = Math.round(100.0 * parseFloat(takeGrams) * parseInt(takeTimesPerDay) * parseInt(takeDays))/100
    const [notes, setNotes] = useState(renew.notes)
    const [formulas, setFormulas] = useState([])
    const [pending, setPending] = useState(true);

    const multiplicator = Math.ceil(totalGrams / parseFloat(formulaWeight))
    const herbsCost = Math.round( 100.0 * parseFloat(formulaCost) * multiplicator ) / 100;
    const [markup, setMarkup] = useState(renew.markup || 3)

    // const totalPrice = Math.round( 100.0 * (herbsCost * parseFloat(markup)  + parseFloat(props.fulfillmentFee) + parseFloat(deliveryCost))) / 100;
    const totalPrice = Math.round( 100.0 * (herbsCost * parseFloat(markup)  + parseFloat(props.fulfillmentFee) + parseFloat(consultFee) )) / 100;

    const [selectedOption, setSelectedOption] = useState({})
    const formulasOptions = formulas.map(
        f =>({
          key: f.formula_id,
          label: f.formula_name,
          value: f
        })
    )
    useEffect(()=>{
        // console.log(renew)
        fetchAcu('office/formulas')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then(data => {
                            setFormulas(data.sort(caseInsensitiveSort('formula_name')))

                            if (renew.formula_id) {
                                const formula = data.reduce(function (acc, curr) {
                                    if (curr.formula_id === renew.formula_id ) acc = curr;
                                    return acc;
                                } ,{})
                                setFormulaName(formula.formula_name);
                                setFormulaWeight(formula.formula_weight);
                                setFormulaCost(formula.formula_cost);
                                props.onFormulaSelect(renew.formula_id);
                                setSelectedOption({
                                    key: formula.formula_id,
                                    label: formula.formula_name,
                                    value: formula
                                })
                            }

                            setPending(false)
                        })
                    else {
                        response.text().then(
                            errorMessage => {
                                console.log(errorMessage)
                                props.onError({status: response.status, message: errorMessage})
                            }
                        )
                    }
                }
            )
            .catch(err => console.log(err))

    }, [props, renew])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const prescription = {
            patient_id: props.patientId,
            formula_id: formulaId,
            take_grams: takeGrams,
            take_days: takeDays,
            total_grams: totalGrams,
            take_times_per_day: takeTimesPerDay,
            notes: notes,
            markup: markup,
            consult_fee: consultFee,
            // delivery_method: deliveryMethod,
            delivery_method: 'local pickup',
            payment_method: paymentMethod,
        }
        console.log(prescription)

        let response = await fetchAcu('office/prescription', {method: 'POST', body: JSON.stringify(prescription) })
        if (response.status === 200) {
            const data = await response.json()
            console.log("Create prescription: " + data)
            prescription.prescription_id = data.prescription_id
            prescription.formula_name = formulaName
            prescription.date = (new Date()).toISOString().split('T')[0]
            prescription.status = 'new'
            if (data.patient_email) {
                prescription.patient_email = data.patient_email
            }
            if (data.herbalist_email) {
                prescription.herbalist_email = data.herbalist_email
            }
            props.onPrescriptionAdd(prescription)
        } else {
            const errorMessage = await response.text()
            console.log("Create prescription error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={style.newPrescriptionTitle}>
                <h3>New prescription</h3>
            </div>
            {( !pending &&
            <Select
                options={formulasOptions}
                required={true}
                value={selectedOption.value ? selectedOption : null}
                onChange={(option) => {
                    setFormulaId(option.value.formula_id);
                    setFormulaName(option.value.formula_name);
                    setFormulaWeight(option.value.formula_weight);
                    setFormulaCost(option.value.formula_cost);
                    props.onFormulaSelect(option.value.formula_id);
                    setSelectedOption(option)
                }}
            />
            )}
            <div className={style.newPrescriptionRow}>
                <label htmlFor={'take-days'}>Take days:</label>
                <input type={'number'} step={1} id={'take-days'} required={true} value={takeDays}
                       onChange={(e)=>setTakeDays(e.target.value)}/>
            </div>
            <div className={style.newPrescriptionRow}>
                <label htmlFor={'take-times-per-day'}>Take times per day:</label>
                <input type={'number'} step={1} id={'take-times-per-day'} required={true} value={takeTimesPerDay}
                       onChange={(e)=>setTakeTimesPerDay(e.target.value)}/>
            </div>
            <div className={style.newPrescriptionRow}>
                <label htmlFor={'take-grams'}>Take grams:</label>
                <input type={'number'} step={0.1} id={'take-grams'} required={true} value={takeGrams}
                       onChange={(e)=>setTakeGrams(e.target.value)}/>
            </div>
            <div className={style.newPrescriptionRow}>
                <label htmlFor={'total-grams'}>Total grams:</label>
                <input type={'number'} value={totalGrams} step={0.1} id={'total-grams'} disabled={true} />
            </div>
            <div className={style.newPrescriptionRow}>
                <label htmlFor={'notes'}>Prescription Notes:</label>
                <textarea id={'notes'} required={true} value={notes}
                          onChange={(e)=>setNotes(e.target.value)}></textarea>
            </div>
            <div className={style.newPrescriptionRow}>
                <div>{"Herbs cost:"}</div>
                <div>{ herbsCost }</div>
            </div>
            <div className={style.newPrescriptionRow}>
                <div>{"Fulfillment fee:"}</div>
                <div>{ props.fulfillmentFee }</div>
            </div>
            <div className={style.newPrescriptionRow}>
                <div>{"Markup:"}</div>
                <div>x<input  type={'number'} step={0.1}  min={0.1} defaultValue={markup}
                             onChange={e => setMarkup(e.target.value)}
                /></div>
            </div>
            <div className={style.newPrescriptionRow}>
                <label htmlFor={'consultFee'}>Consult fee:</label>
                <Select id={'consultFee'}
                        autoFocus={false}
                        blurInputOnSelect={true}
                        isSearchable={false}
                        required={true}
                        closeMenuOnSelect={true}
                        value={consultFeeOptionSelected}
                        options={consultFeeOptions}
                        className={style.methodSelect}
                        onChange={newValue => setConsultFee(newValue.value)}
                />
            </div>
            <div className={style.newPrescriptionRow}>
                <div>{"Total price:"}</div>
                <div>{ totalPrice }</div>
            </div>

            <div className={style.newPrescriptionRow}>
                <label htmlFor={'paymentMethod'}>Payment method:</label>
                <Select id={'paymentMethod'}
                        autoFocus={false}
                        blurInputOnSelect={true}
                        isSearchable={false}
                        required={true}
                        closeMenuOnSelect={true}
                        options={[{label: 'Cash', value: 'local'}, {label: 'Jane', value: 'Jane'}]}
                        className={style.methodSelect}
                        onChange={newValue => setPaymentMethod(newValue.value)}
                />
            </div>
            {/*<div className={style.newPrescriptionRow}>*/}
            {/*    <label htmlFor={'deliveryMethod'}>Delivery method:</label>*/}
            {/*    <Select id={'deliveryMethod'}*/}
            {/*            className={style.methodSelect}*/}
            {/*            autoFocus={false}*/}
            {/*            blurInputOnSelect={true}*/}
            {/*            isSearchable={false}*/}
            {/*            required*/}
            {/*            closeMenuOnSelect={true}*/}
            {/*            options={[{label: 'local pickup', value: 'local pickup'}, {label: 'USPS', value: 'USPS'}]}*/}
            {/*            onChange={ newValue => { setDeliveryMethod(newValue.value) } }*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className={style.newPrescriptionRow}>*/}
            {/*    <div>{"Delivery cost:"}</div>*/}
            {/*    <div>{ deliveryCost }</div>*/}
            {/*</div>*/}
            <div className={style.newPrescriptionButtonsRow}>
                <input className={style.newPrescriptionButton} type={'submit'}
                       value={ "Submit" } />
                <button className={style.newPrescriptionButton}
                        onClick={(e)=>{e.preventDefault(); props.onCancel()}}
                >Cancel</button>
            </div>
        </form>
    )
}

export default NewPrescription
