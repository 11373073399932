import React, {useEffect, useMemo, useState} from "react";
import * as style from "./formulaDetails.module.scss"
import Sprintf from "sprintf-js";
import {caseInsensitiveSort} from "../data-table-utils";
import DataTable from "react-data-table-component";
import {fetchAcu} from "../fetchacu";
import styled from "styled-components";
import Select from "react-select";
import newFormula from "../formulas/newFormula/newFormula";

const CheckBox = styled.input.attrs({type: "checkbox"})`
    margin-left: 10px;
    margin-right: 0px;
`;

const columns = [
    {
        id: 'code',
        name: 'Code',
        selector: row => row.herb_code,
        format: row => {return Sprintf.sprintf('%03d', row.herb_code)},
        width: '60px',
    },
    {
        id: 'herbName',
        name: 'Herb Name',
        selector: row => row.herb_name,
        sortable: true,
        sortFunction: caseInsensitiveSort('herb_name'),
        cell: row => { let ar =  [row.herb_name];
            if (row.common_name) {
                ar.push('(' + row.common_name+ ')')
            }
            return ar.join(' ');
        },
    },
]



const FormulaDetails = (props) => {
    const [formula, setFormula] = useState({formula_id: props.formulaId, components: [], originalComponents: []})
    const [originalFormula, setOriginalFormula] = useState(JSON.parse(JSON.stringify(formula)));
    const [bottles, setBottles] = useState({})
    const [herbsAvailable, setHerbsAvailable] = useState([])
    const [packSubmitted, setPackSubmitted] = useState(false)
    // const [location, setLocation] = useState('')
    const [isAmend, setIsAmend] = useState(false)
    const [selectedComponent, setSelectedComponent] = useState(null)
    const [selectedParts, setSelectedParts] = useState(1)
    const [isAmended, setIsAmended] = useState(false)
    const onError = props.onError


    let myColumns = isAmend ? (
        columns.concat(
            {
                name: 'Parts',
                selector: row => row.parts,
                // format: row => (<input class={style.parts} type={'number'} value={row.parts} step={0.1} onChange={ e => { changeComponent(row.herb_code, e.target.value) } } />),
                format: row => (<input className={style.parts}
                                       type={'number'}
                                       value={row.parts}
                                       onChange={ e => { amendEditComponent(row.herb_code, e.target.value) } }
                                       step={0.1} />),
                width: '100px',
            },
            {
                name: '',
                cell: (row) => (<>
                    <input type={'button'} onClick={(e) => {
                        e.preventDefault();
                        amendDeleteComponent(row.herb_code)
                        return false
                    }} className={style.buttonRemoveComponent} value={'X'} />
                </>),
                width: '45px',
            }
        ))
        : (
            columns.concat(
                {
                    name: 'Parts',
                    selector: row => row.parts,
                    format: row => {
                        if (!row || !row.hasOwnProperty('parts')) {
                            return ''
                        }
                        row.parts = `${row.parts}`
                        return ( row.parts === "0.5" ? '½' : (row.parts.match(/\.0$/) ? parseInt(row.parts) : row.parts ) )
                    },
                    width: '80px',
                }
        ))


    const formulaComponents = formula.components.reduce((acc, r) => {
        r.grams = Math.round( 100.0 * r.parts * parseFloat(props.multiplicator) ) / 100;
        acc[r.component_id] = r
        return acc
    }, {})

    const canPack = !packSubmitted &&  Object.keys(bottles).length > 0 && Object.keys(bottles).reduce( (r, k) => (r && bottles[k].selected_bottle_id), true)

    const onFormulaLoad = (loadedFormula) => {
        if (props.showPack) {
            fetchAcu('stock/formulabottles/' + formula.formula_id
                + '?multiplicator=' + props.multiplicator
            + '&location=' + props.location)
                .then(
                    response => {
                        if (response.status === 200)
                            response.json().then(data => {
                                setBottles(data.components)
                                if (data.herbsAvailable) {
                                    setHerbsAvailable(data.herbsAvailable)
                                }
                                // console.log(JSON.stringify(data.components, null, 2))
                                setFormula(loadedFormula)
                            })
                        else
                            response.text().then(err => {
                                console.log(err);
                                onError({status: response.status, message: err})
                            })
                    }
                ).catch(err => {
                console.log(err);
                onError({status: 1, message: err})
            })
        } else {
            setFormula(loadedFormula)
        }
        // console.log(JSON.stringify(loadedFormula, null, 2))
    }


    useEffect( () => {
        if (formula.formula_id > 0) {
            fetchAcu('office/formula/' + formula.formula_id)
                .then(
                    response => {
                        if (response.status === 200)
                            response.json().then(data => onFormulaLoad(data))
                        else
                            response.text().then(err => {
                                console.log(err);
                                onError({status: response.status, message: err})
                            })
                    }
                ).catch(err => {
                console.log(err);
                onError({status: 1, message: err})
            })
        }
    }, [formula.formula_id, props.location, props.formulaId])


    useEffect(() => {
        const handleKeyDown = (event) => {
            // console.log(`${event.key} key pressed!`);
            if (event.key === "Escape") {
                console.log("Escape key pressed!");
                event.preventDefault();
                console.log(`isAmend: ${isAmend} isAmended: ${isAmended}`);
                // Ваши действия при нажатии клавиши Escape
                if (isAmend) {
                    event.stopPropagation()
                }
                if (isAmend && !isAmended) {
                    setIsAmend(false)
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isAmend, isAmended]);

    const preFormulaColumns = (!props.prescriptionId && !isAmend) ? myColumns : myColumns.concat(
        {
            id: "grams",
            name: "Grams",
            selector: row => row.grams,
            width: '80px'
        }
    )
    const formulaColumns = !(props.showPack && props.location) ? preFormulaColumns : ((isAmend || props.showPack)  ? preFormulaColumns : myColumns).concat(
        {
            id: "bottles",
            name: "Gramms left at " + props.location,
            cell: row => { return (<div className={style.radioCell}>
                    {( !!isAmend && (
                        <div>{herbsAvailable.reduce((a, h) => {
                            return (row.herb_code === h.herb_code && h.hasOwnProperty('current_weight')) ?  h.current_weight : a
                        }, '')}</div>
                    ))}
                    {( !isAmend &&
                        bottles[row.component_id] && bottles[row.component_id].bottles.map( b => {
                        return (<div><input type={"radio"}
                                   // defaultChecked={b.bottle_id === bottles[row.component_id].selected_bottle_id }
                                   checked={b.bottle_id === bottles[row.component_id].selected_bottle_id }
                                   disabled={b.disabled}
                                   // onClick={
                                   //      e => {
                                   //          const newBottles = JSON.parse(JSON.stringify(bottles));
                                   //          newBottles[row.component_id].selected_bottle_id = b.bottle_id;
                                   //          setBottles(newBottles)
                                   //      }
                                   // }
                                   name={row.herb_code}/><label htmlFor={row.herb_code}>{b.current_weight}
                                    {( b.disabled && <span style={{color: 'red', fontStyle: 'italic'}}> NOT ENOUGH!</span> )}
                                    </label>
                                </div>)
                         })
                    )}
                </div>
            )
        }}
    )

    const onPrescriptionPack = async (e) => {
        e.preventDefault();
        setPackSubmitted(true)
        console.log("pack pushed");
        const bottlesData = Object.keys(bottles).map(componentId => ({
            component_id: componentId,
            bottle_id: bottles[componentId].selected_bottle_id,
            grams: formulaComponents[componentId].grams,
        }))
        let response = await fetchAcu('stock/pack/' + props.prescriptionId,
            {method: 'POST', body: JSON.stringify(bottlesData)})
        if (response.status === 200) {
            const okMessage = await response.text()
            console.log("Pack: " + okMessage)
            props.onPrescriptionPack(props.prescriptionId)
        } else {
            const errorMessage = await response.text()
            setPackSubmitted(false)
            console.log("Pack error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    const printPrescription = () => {
        const uniqueIframeId = 'printIframe'
        let pri
        if (document.getElementById(uniqueIframeId)) {
            pri = document.getElementById(uniqueIframeId).contentWindow
        } else {
            const iframe = document.createElement('iframe')
            iframe.setAttribute('title', uniqueIframeId)
            iframe.setAttribute('id', uniqueIframeId)
            iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
            document.body.appendChild(iframe)
            pri = iframe.contentWindow
        }
        pri.document.open()
/*
        pri.document.write('<html><head></head><body style="width:1.7in;font-family: sans-serif;font-size: 0.11in;">')
        pri.document.write('Patient Name: <span style="font-size: 0.14in">' + props.patientName + '</span><br/>')
        pri.document.write('Formula Code: ' + formula.formula_name + '<br/>')
        pri.document.write('Grams Prescribed: ' + props.totalGrams + '<br/>')
        pri.document.write( props.date + '<br/><br/>')

        if ( formula.allergens ) {
            pri.document.write( 'Allergens: ' + formula.allergens + '<br/><br/>')
        }

        formula.components.forEach(r => {
            pri.document.write('<div style="display:flex;justify-content: space-between;font-size: 0.11in;"><div>'+r.herb_name
                +'</div><div>'+(Math.round(100.0 * parseFloat(r.parts) * parseFloat(props.multiplicator))/100)+'g</div></div>')
        })
        pri.document.write( '<br/>Prescription notes:<br/>')
        pri.document.write( '<div style="font-size: 0.14in">'+props.notes+'</div>')
        pri.document.write('</body></html>')
 */
        pri.document.write('<html><head>')
        pri.document.write('<style>.label {\n' +
            '            overflow: hidden;\n' +
            '            position: relative;\n' +
            '            width: 80mm;\n' +
            '            display: flex;\n' +
            '            padding: 0mm 4mm;\n' +
            '            flex-direction: column;\n' +
            '        }\n' +
            '        .headimg {\n' +
            '            position: relative;\n' +
            '            max-width: 100%;\n' +
            '            height: auto;\n' +
            '            margin-bottom: 4mm;\n' +
            '        }</style>')
        pri.document.write('</head><body style="width:1.7in;font-family: sans-serif;font-size: 0.11in;">')
        pri.document.write('<div class="label">')
        pri.document.write('<img class="headimg" src="/api/v1/stock/prescriptionlogo/' + props.prescriptionId + '" />')
        pri.document.write('<div class="prescr">')
        pri.document.write('Patient Name: <span style="font-size: 0.14in">' + props.patientName + '</span><br/>')
        pri.document.write('Formula Code: ' + formula.formula_name + '<br/>')
        pri.document.write('Grams Prescribed: ' + props.totalGrams + '<br/>')
        pri.document.write( props.date + '<br/><br/>')

        if ( formula.allergens ) {
            pri.document.write( 'Allergens: ' + formula.allergens + '<br/><br/>')
        }

        formula.components.forEach(r => {
            pri.document.write('<div style="display:flex;justify-content: space-between;font-size: 0.11in;"><div>'+r.herb_name
                +'</div><div>'+(Math.round(100.0 * parseFloat(r.parts) * parseFloat(props.multiplicator))/100)+'g</div></div>')
        })
        pri.document.write( '<br/>Prescription notes:<br/>')
        pri.document.write( '<div style="font-size: 0.14in">'+props.notes+'</div>')
        pri.document.write('</div></div>')
        pri.document.write('</body></html>')
        pri.document.close()
        pri.focus()
        setTimeout( function(){ pri.print() }, 1000 )
    }

    const amendAddComponent = (comp, parts) => {
        const newFormula = JSON.parse(JSON.stringify(formula));
        const components = newFormula.components.filter(e => (e.herb_code !== comp.herb_code));
        console.log(JSON.stringify(components,null,2));
        comp.parts=parts;
        comp.grams= (Math.round(100.0 * parseFloat(parts) * parseFloat(props.multiplicator))/100);
        for (const h of herbsAvailable) {
            if (h.herb_code === comp.herb_code) {
                comp.current_weight = h.current_weight
            }
        }
        newFormula.components = components.concat(comp);
        setFormula(newFormula);
        setIsAmended(true);
    }
    const amendEditComponent = (herb_code, parts) => {
        const newFormula = JSON.parse(JSON.stringify(formula));
        for (const c of newFormula.components) {
            if(c.herb_code == herb_code) {
                c.parts = parts
            }
        }
        setFormula(newFormula);
        setIsAmended(true);
    }
    const amendDeleteComponent = (herb_code) => {
        const newFormula = JSON.parse(JSON.stringify(formula));
        newFormula.components = newFormula.components.filter(e => (e.herb_code !== herb_code));
        setFormula(newFormula);
        setIsAmended(true);
    }

    const options = herbsAvailable.map(r => ({label: r.herb_name, value: r}));

    const subHeaderComponentMemoDetails = useMemo(() => {
            return (
                <div className={style.formulaDetailsTableHeader}>
                    {( !isAmend &&
                        <>
                            {(props.showPack && (<>
                                    <button className={style.packButton}
                                            disabled={!canPack}
                                            onClick={onPrescriptionPack}>Pack</button>

                                    {( !!props.location && <button className={style.amendButton} onClick={(e) => {
                                        e.preventDefault();
                                        setOriginalFormula(JSON.parse(JSON.stringify(formula)))
                                        setIsAmend(true)
                                    }}>Amend
                                    </button> )}
                                </>)
                            )}
                            {(props.showPrint &&
                                <button className={style.printButton} onClick={(e) => {
                                    e.preventDefault();
                                    printPrescription('pre' + props.prescription_id, 'printframe');
                                    console.log('data')
                                }}>Print</button>
                            )}
                        </>
                    )}
                    {( !!isAmend &&
                        <>
                            <button className={style.amendSaveButton}
                            disabled={!isAmended}
                                    onClick={(e) => {
                                e.preventDefault();
                                console.log(JSON.stringify(formula, null, 2));
                                setIsAmend(false)
                                setIsAmended(false);
                                props.onAmend(formula.components)
                            }}>Save Amendments</button>
                            <button className={style.amendCancelButton} onClick={(e) => {
                                e.preventDefault();
                                setFormula(JSON.parse(JSON.stringify(originalFormula)))
                                setIsAmend(false)
                                setIsAmended(false);
                            }}>Cancel Amendments</button>
                        </>
                    )}

                    <h3 className={style.formulaDetailsHeader}>
                        Formula {formula.formula_name}
                        {(formula.is_classic &&
                            <sup>classic</sup>
                        )}
                    </h3>
                    {(formula.allergens &&
                        <div className={style.formulaDetailsNotes}><span
                            className={style.allergens}>Allergens: {formula.allergens}</span></div>
                    )}
                    {(formula.notes &&
                        <div className={style.formulaDetailsNotes}>Notes: {formula.notes}</div>
                    )}
                    {( (props.showPack && !isAmend) &&
                        <div>
                            <CheckBox id={"checkBrookline"} checked={props.location === 'Brookline'}
                                      onChange={() => {
                                          props.onChangeLocation(props.location !== 'Brookline' ? 'Brookline' : 'Dedham')
                                      }}/>
                            <label style={{color: 'blue'}} htmlFor={"checkBrookline"}>Brookline</label>
                            <CheckBox id={"checkDedham"} checked={props.location === 'Dedham'}
                                      onChange={() => {
                                          props.onChangeLocation(props.location !== 'Dedham' ? 'Dedham' : 'Brookline')
                                      }}/>
                            <label style={{color: 'red'}} htmlFor={"checkDedham"}>Dedham</label>
                        </div>
                    )}
                    { isAmend && (
                        <div className={style.addComponentContainer}>
                            <Select className={style.herbSelect} value={selectedComponent}
                                    onChange={(newValue) =>
                                    {
                                        console.log(newValue)
                                        setSelectedComponent(newValue)
                                    }
                                    }
                                    options={options}
                            />
                            <input className={style.addPartsInput} type={'number'}
                                   step={0.1}
                                   value={selectedParts}
                                   onChange={(e)=>{
                                       e.preventDefault()
                                       setSelectedParts(e.target.value)
                                   }}
                            />
                            <input type={'button'} className={style.addPartsButton}
                                   onClick={(e)=>{
                                       e.preventDefault();
                                       // console.log(components)
                                       console.log(JSON.stringify({selectedComponent, selectedParts}, null, 2))
                                       amendAddComponent(selectedComponent.value, selectedParts)
                                       // const filtered = components.filter(i => i.herb_code !== selectedComponent.value.herb_code)
                                       // setComponents(filtered.concat({ ...selectedComponent.value, parts: selectedParts.toString()}));
                                       return false;
                                   }}
                                   value={'Add'}
                            />
                        </div>
                    )}

                </div>
            );
        },
        [formula, props.location, props.showPack, props.showPrint, canPack, onPrescriptionPack, printPrescription]);


    return (
        <div style={{minWidth: "580px"}}>
            {( !!isAmend && <div style={{position:'absolute', left: '-1000px', height: '800px', width: '2000px', backdropFilter: 'blur(4px)' }}></div> )}
            <form>
                <DataTable
                    columns={formulaColumns}
                    keyField={'code'}
                    data={formula.components}
                    persistTableHead
                    subHeader
                    subHeaderComponent={subHeaderComponentMemoDetails}
                    highlightOnHover={true}
                    dense={true}
                    defaultSortFieldId={'herbName'}
                />
            </form>
        </div>
    )
}

export default FormulaDetails
