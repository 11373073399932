import React, {useEffect, useState} from "react";
import * as style from "./prescriptions.module.scss"
import DataTable from "react-data-table-component";
import {fetchAcu} from "../../fetchacu";
import FormulaDetails from "../../formulaDetails/formulaDetails";
import styled from "styled-components";
import NewPrescription from "../newPrescription/newPrescription";
import DeleteDialog from "../../deleteDialog/deleteDialog";
import InfoDialog from "../../infoDialog/infoDialog";

const ExpandedComponent = ({ data }) => {
    const d = JSON.parse(JSON.stringify(data));
    delete d.prescription_id
    delete d.formula_id
    delete d.formula_name
    delete d.date
    delete d.notes
    delete d.status
    return (<div className={style.prescriptionExpandContainer}>
        <pre id={'pre' + data.prescription_id}>
            Take days: {d.take_days}<br/>
            Take grams: {d.take_grams}<br/>
            Take times per day: {d.take_times_per_day}<br/>
            Total grams: {d.total_grams}<br/>
            Markup: {d.markup}<br/>
            {( d.fulfillment_fee > 0 && (
                    <div>Fulfillment fee: {d.fulfillment_fee}<br/></div>
                )
                )}
            {( d.tracking_code && (
                    <div>Tracking code: {d.tracking_code}<br/></div>
                )
            )}
        </pre>
    </div>)
};

const NewPrescriptionButton = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 40px;
`;

const Prescriptions = (props) => {
    const [prescriptions, setPrescriptions] = useState([])
    const [pending, setPending] = useState(true)
    const [formulaId, setFormulaId] = useState(0)
    const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(0)
    const [showNewPrescription, setShowNewPrescription] = useState(false)
    const [showReNewPrescription, setShowReNewPrescription] = useState(false)
    const showNew = showNewPrescription || showReNewPrescription
    const [prescriptionToDel, setPrescriptionToDel] = useState({})
    const selectedPrescription = prescriptions.reduce(function(acc, curr){
        if (selectedPrescriptionId > 0 && selectedPrescriptionId === curr.prescription_id)  return curr;
        return acc}, {})
    const [emailSent, setEmailSent] = useState({patient: null, herbalist: null})
    // const [selectedFormulaId, setSelectedFormulaId] = useState(0);
    const columns = [
        {
            id: 'formula',
            name: 'Formula',
            selector: row => row.formula_name,
            sortable: true,
            grow: 1,
        },
        {
            name: 'Date',
            id: 'date',
            selector: row => row.date,
            width: '110px',
            //grow: 1,
        },
        {
            name: 'Notes',
            id: 'notes',
            selector: row => row.notes,
            wrap: true,
            grow: 2,
        },
        {
            name: "Status",
            id: 'status',
            selector: row => row.status,
        }
    ]

    useEffect(() => {
        fetchAcu('office/patient/' + props.patient.patient_id + '/prescriptions')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {console.log(data); setPrescriptions(data); setPending(false)})
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
            console.log(err);
            props.onError({status: 1, message: err});
        })
    }, [props])

    const handlePrescriptionAdd = (prescription) => {
        setPrescriptions([prescription, ...prescriptions]);
        setShowNewPrescription(false)
        setShowReNewPrescription(false)
        setSelectedPrescriptionId(prescription.prescription_id);
        setFormulaId(prescription.formula_id);
        if ( prescription.patient_email ) {
            setEmailSent({patient: prescription.patient_email, herbalist: prescription.herbalist_email})
        }
    }

    const conditionalRowStyles = [
        {
            when: row => row.prescription_id === selectedPrescriptionId,
            style: {
                backgroundColor: '#ffffcc',
            },
        },
    ];

    const deletePrescription = async (prescriptionId) => {
        let response = await fetchAcu('office/prescription/' + prescriptionId, {method: 'DELETE'});
        if (response.status !== 200) {
            let errorMessage = await response.text()
            props.onError({status: response.status, message: errorMessage})
        } else {
            let okMessage = await response.text();
            console.log("Delete: " + okMessage)
            setPrescriptions(prescriptions.filter(row => (row.prescription_id !== prescriptionId)))
            setFormulaId(0)
            setPrescriptionToDel({})
            setSelectedPrescriptionId(0)
        }

    }

    const renewAttrs = {}
    if (showReNewPrescription) {
        renewAttrs.renewPrescription = selectedPrescription
    }

    return (
        <div className={style.prescriptionsContainer}>
            <div className={style.prescriptionsLeft}>
                {( !showNew &&
                <NewPrescriptionButton
                    onClick={()=>{setFormulaId(0);setShowNewPrescription(true)}}>New Prescription</NewPrescriptionButton>
                )}
                {( (!showNew && selectedPrescriptionId > 0) &&
                    <NewPrescriptionButton
                        onClick={()=>{setFormulaId(0);setShowReNewPrescription(true)}}>Renew Prescription</NewPrescriptionButton>
                )}
                {( !showNew &&
                <DataTable
                    columns={columns}
                    data={prescriptions}
                    persistTableHead
                    keyField={'date'}
                    progressPending={pending}
                    defaultSortFieldId={'date'}
                    defaultSortAsc={false}
                    conditionalRowStyles={conditionalRowStyles}
                    pagination
                    paginationPerPage={15}
                    highlightOnHover={true}
                    dense={true}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    onRowClicked={row => { setSelectedPrescriptionId(row.prescription_id); console.log(row.formula_id); setFormulaId(row.formula_id) }}
                />
                )}
                {( prescriptionToDel.prescription_id &&
                    <DeleteDialog
                        onCloseModal={() => setPrescriptionToDel({})}
                        onClickDelete={() => {deletePrescription(prescriptionToDel.prescription_id); setPrescriptionToDel({})} }
                        title={(<>{'You’re about to delete prescription '}<em>{prescriptionToDel.date}</em></>)}
                    />
                )}
                {( emailSent.patient &&
                <InfoDialog
                    onCloseModal={() => {
                        setEmailSent({patient: null, herbalist: null});
                    }}
                    title={(<>{'Prescription was emailed for payment to '}<em>{emailSent.patient}</em>{' and cc`d to '}<em>{emailSent.herbalist}</em></>)}
                />
                )}
                {( !!showNew &&
                        <NewPrescription
                            patientId={props.patient.patient_id}
                            onError={props.onError}
                            onCancel={()=>{
                                setFormulaId(0);
                                setSelectedPrescriptionId(0);
                                setShowNewPrescription(false)
                                setShowReNewPrescription(false)}
                            }
                            fulfillmentFee={props.user.fulfillment_fee}
                            onFormulaSelect={newFormulaId => {
                                if (showReNewPrescription) {
                                    renewAttrs.renewPrescription.formula_id = newFormulaId
                                }
                                setFormulaId(newFormulaId)
                               }
                            }
                            onPrescriptionAdd={handlePrescriptionAdd}
                            { ...renewAttrs }
                        />
                )}
            </div>
            <div className={style.prescriptionsRight}>
                <FormulaDetails key={formulaId}  formulaId={formulaId} onError={props.onError} />
            </div>
        </div>
    )
}

export default Prescriptions;