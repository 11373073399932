import React, {useEffect, useState} from "react"
import * as style from './fulfillment.module.scss'
import {caseInsensitiveSort} from "../data-table-utils";
import DataTable from 'react-data-table-component'
import {fetchAcu} from "../fetchacu";
import PrescriptionDetails from "./prescriptionDetails/prescriptionDetails";
import styled from "styled-components";
import DeleteDialog from "../deleteDialog/deleteDialog";


const CheckBox = styled.input.attrs({type: "checkbox"})`
    margin-right: 10px;
`;
const TextField = styled.input`
      	height: 34px;
      	width: 200px;
      	border-radius: 3px;
      	border-top-left-radius: 5px;
      	border-bottom-left-radius: 5px;
      	border-top-right-radius: 0;
      	border-bottom-right-radius: 0;
      	border: 1px solid #e5e5e5;
      	padding: 0 32px 0 16px;
      
      	&:hover {
        		cursor: pointer;
        	}
      `;
const ClearButton = styled.button`
    	border-top-left-radius: 0;
	    border-bottom-left-radius: 0;
	    border-top-right-radius: 5px;
	    border-bottom-right-radius: 5px;
	    height: 34px;
	    width: 32px;
	    text-align: center;
	    display: flex;
	    align-items: center;
        justify-content: center;
        cursor: pointer;
    `;

const FilterComponent = ({ filterText, onFilter, showNew, onShowNew, showPaid, onShowPaid,
                             // showPacked, onShowPacked, showShipped, onShowShipped,
                             showCompleted, onShowCompleted,  onClear }) => (
    <div className={style.filterComponent}>
        <div className={style.showStatus}>
            <CheckBox id={"showNew"} defaultChecked={showNew} onChange={onShowNew}/>
            <label htmlFor={"showNew"}>show New</label>
        </div>
        <div className={style.showStatus}>
            <CheckBox id={"showPaid"} defaultChecked={showPaid} onChange={onShowPaid}/>
            <label htmlFor={"showPaid"}>show Paid</label>
        </div>
        {/*<div className={style.showStatus}>*/}
        {/*    <CheckBox id={"showPacked"} defaultChecked={showPacked} onChange={onShowPacked}/>*/}
        {/*    <label htmlFor={"showPacked"}>show Packed</label>*/}
        {/*</div>*/}
        {/*<div className={style.showStatus}>*/}
        {/*    <CheckBox id={"showShipped"} defaultChecked={showShipped} onChange={onShowShipped}/>*/}
        {/*    <label htmlFor={"showShipped"}>show Shipped</label>*/}
        {/*</div>*/}
        <div className={style.showStatus}>
            <CheckBox id={"showCompleted"} defaultChecked={showCompleted} onChange={onShowCompleted}/>
            <label htmlFor={"showCompleted"}>show Completed</label>
        </div>
        <TextField
            id="search"
            type="text"
            placeholder="Filter By Text"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>X</ClearButton>
    </div>
);

const Fulfillment = (props) => {
    const [rows, setRows] = useState([]);
    const [pending, setPending] = useState(true);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showPrescriptionDetails, setShowPrescriptionDetails] = useState({})
    const [filterText, setFilterText] = useState('');
    const [showCompleted, setShowCompleted] = useState(false);
    // const [showShipped, setShowShipped] = useState(true);
    // const [showPacked, setShowPacked] = useState(true);
    const [showPaid, setShowPaid] = useState(true);
    const [showNew, setShowNew] = useState(true);
    const [prescriptionToDel, setPrescriptionToDel] = useState({})
    const [reloadNeeded, setReloadNeeded] = useState(0);
    const [packedMessage, setPackedMessage] = useState('')

    const columns = [
        {
            id: "prescriptionId",
            name: "ID",
            selector: row => row.prescription_id,
            sortable: true,
            width: '80px',
        },
        {
            id: 'herbalistName',
            name: 'Herbalist',
            selector: row => row.herbalist_name,
            sortFunction: caseInsensitiveSort('herbalist_name'),
        },
        {
            id: 'patientName',
            name: 'Patient',
            selector: row => row.patient_name,
            sortFunction: caseInsensitiveSort('patient_name'),
        },
        {
            id: 'formulaName',
            name: 'Formula',
            sortable: true,
            selector: row => row.formula_name,
        },
        {
            id: 'date',
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
        {
            id: 'status',
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => <span className={style['status' + row.status]}>{row.status}</span>
        },
        {
            id: 'updatedAt',
            name: 'Updated at',
            selector: row => row.updated_at,
            sortable: true,
        },
        {
            id: 'buttons',
            name: '',
            cell: (row) => (<>
                <button onClick={() => setShowPrescriptionDetails(row)}
                        className={style.buttonEditPrescription}>&#x270D;</button>
                {( !! props.user.isAdmin &&
                <button onClick={() => setPrescriptionToDel(row)}
                        className={style.buttonRemovePrescription}>x</button>
                )}
            </>),
            width: props.user.isAdmin ? '110px' : '70px',
        },
    ]

    useEffect(() => {
        fetchAcu('stock/prescriptions')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then( data => {setRows(data); setPending(false)})
                    else {
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                    }
                }
            ).catch(err => {
            console.log(err);
            props.onError({status: 1, message: err});
        })
    }, [props, reloadNeeded]);


    const deletePrescription = async (prescriptionId) => {
        let response = await fetchAcu('office/prescription/' + prescriptionId, {method: 'DELETE'})
        if ( response.status === 200 ) {
            const okMessage = await response.text()
            setRows( rows.filter( row => (row.prescription_id !== prescriptionId) ))
            console.log("delete prescription: " + okMessage)
        } else {
            const errorMessage = await response.text()
            console.log("delete prescription error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    };


    const subHeaderComponentMemo = React.useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                }
            };
            return (
                <div className={style.fulfillmentTableHeader}>
                    <FilterComponent
                        onClear={handleClear}
                        filterText={filterText}
                        onFilter={e => setFilterText(e.target.value)}
                        showNew={showNew}
                        onShowNew={()=>setShowNew(!showNew)}
                        showPaid={showPaid}
                        onShowPaid={()=>setShowPaid(!showPaid)}
                        // showPacked={showPacked}
                        // onShowPacked={()=>setShowPacked(!showPacked)}
                        // showShipped={showShipped}
                        // onShowShipped={()=>setShowShipped(!showShipped)}
                        showCompleted={showCompleted}
                        onShowCompleted={()=>setShowCompleted(!showCompleted)}
                    />
                </div>
            );
        },
        [filterText, showNew, showPaid,
            // showPacked, showShipped,
            showCompleted, resetPaginationToggle]);


    const handlePrescriptionUpdate = (updated, packed) => {
        console.log(updated)
        const updatedPrescription = JSON.parse(JSON.stringify(showPrescriptionDetails))
        updatedPrescription.status = updated.status
        updatedPrescription.tracking_code = updated.tracking_code
        setRows( rows.filter(r => r.prescription_id !== updated.prescription_id).concat(updatedPrescription) )
        setShowPrescriptionDetails({})
        if(packed){
            setPackedMessage(`${updatedPrescription.formula_name} for ${updatedPrescription.patient_name} Packed`)
        }
    }

    const filteredRows = rows.filter(
        r => ( showNew || r.status !== 'new' )
    ).filter(
        r => ( showPaid || r.status !== 'paid' )
    // ).filter(
    //     r => ( showPacked || r.status !== 'packed' )
    // ).filter(
    //     r => ( showShipped || r.status !== 'shipped' )
    ).filter(
        r => ( showCompleted || r.status !== 'completed' )
    ).filter(
        item => ( filterText.length === 0 ? true
            : ( (item.herbalist_name && item.herbalist_name.toLowerCase().includes(filterText.toLowerCase()))
                || ( item.patient_name && item.patient_name.toLowerCase().includes(filterText.toLowerCase()))
                || ( item.formula_name && item.formula_name.toLowerCase().includes(filterText.toLowerCase()))
            ) )
    )
    if (packedMessage) {
        return (
            <div className={style.packedDiv}>
                <h1>{packedMessage}</h1>
                <button className={style.packedButton}
                  onClick={(e) => {
                    e.preventDefault()
                    setPackedMessage('')
                }}>
                    OK
                </button>
            </div>
        )
    }
    return (
        showPrescriptionDetails.prescription_id
            ?
        <PrescriptionDetails onError={props.onError}
                             prescription={showPrescriptionDetails}
                             onCancel={(reload)=>{
                                 setShowPrescriptionDetails({})
                                 if (reload) { setReloadNeeded(reloadNeeded + 1); }
                             }}
                             onPrescriptionUpdate={(prescription, packed) => handlePrescriptionUpdate(prescription, packed)}
        />
            :
        <div className={style.fulfillment}>
            {( prescriptionToDel.prescription_id &&
                <DeleteDialog
                    key={prescriptionToDel.prescription_id}
                    onCloseModal={() => setPrescriptionToDel({})}
                    onClickDelete={() => {deletePrescription(prescriptionToDel.prescription_id); setPrescriptionToDel({})} }
                    title={(<>{'You’re about to delete prescription '}<em>{prescriptionToDel.patient_name}</em></>)}
                />
            )}
            <DataTable
                keyField={'prescriptionId'}
                columns={columns}
                data={filteredRows}
                progressPending={pending}
                persistTableHead
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                paginationPerPage={15}
                paginationResetDefaultPage={resetPaginationToggle}
//                expandableRows
//                expandableRowDisabled={row => row.inventory === 0}
//                expandableRowsComponent={ExpandedComponent}
                highlightOnHover={true}
//                conditionalRowStyles={conditionalRowStyles}
                dense={true}
                defaultSortFieldId={'date'}
                defaultSortAsc={false}
            />
        </div>
    )
}

export default Fulfillment
