import React, {useEffect, useState} from "react"
import * as style from "./formulas.module.scss";
import styled from "styled-components";
import {fetchAcu} from "../fetchacu";
import DataTable from "react-data-table-component";
import EditFormula from "./editFormula/editFormula";
import DeleteDialog from "../deleteDialog/deleteDialog";
import NewFormula from "./newFormula/newFormula";
import FormulaDetails from "../formulaDetails/formulaDetails";
import {caseInsensitiveSort} from "../data-table-utils";

const CheckBox = styled.input.attrs({type: "checkbox"})`
  margin-right: 10px;
`;

const TextField = styled.input`
      	height: 34px;
      	width: 200px;
      	border-radius: 3px;
      	border-top-left-radius: 5px;
      	border-bottom-left-radius: 5px;
      	border-top-right-radius: 0;
      	border-bottom-right-radius: 0;
      	border: 1px solid #e5e5e5;
      	padding: 0 32px 0 16px;
      
      	&:hover {
        		cursor: pointer;
        	}
      `;
const ClearButton = styled.button`
    	border-top-left-radius: 0;
	    border-bottom-left-radius: 0;
	    border-top-right-radius: 5px;
	    border-bottom-right-radius: 5px;
	    height: 34px;
	    width: 32px;
	    text-align: center;
	    display: flex;
	    align-items: center;
        justify-content: center;
        cursor: pointer;
    `;


const FilterComponent = ({ filterText, onFilter, showClassic, onShowClassic,  onClear }) => (
    <div className={style.filterComponent}>
        <div className={style.showClassic}>
            <CheckBox id={"showClassic"} defaultChecked={showClassic} onChange={onShowClassic}/>
            <label htmlFor={"showClassic"}>Classic</label>
        </div>
        <TextField
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>X</ClearButton>
    </div>
);

const AddFormulaButton = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 5px;
`;

const Formulas = (props) => {
    const [rows, setRows] = useState([])
    const [filterText, setFilterText] = useState('')
    const [showClassic, setShowClassic] = useState(false)
    const [pending, setPending] = useState(true)
    const [showFormulaDetails, setShowFormulaDetails] = useState({})
    const [editFormula, setEditFormula] = useState({})
    const [newFormula, setNewFormula] = useState({})
    const [formulaToDel, setFormulaToDel] = useState({});
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)

    useEffect( () => {
        fetchAcu('office/formulas')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then(data => {setRows(() => data); setPending(() => false)})
                    else
                        response.text().then(err => {console.log(err); props.onError({status: response.status, message: err})})
                }
            ).catch(err => {console.log(err); props.onError({status: 1, message: err})})
    }, [props]);

    const filteredItems = rows.filter(
        item => ( filterText.length === 0 ? true
                   : (item.formula_name && item.formula_name.toLowerCase().includes(filterText.toLowerCase()))
                )
    ).filter(
        item => ( showClassic
            ?  ( item.formula_name && item.is_classic )
            : true
        )
    );

    const handleOnCreateFormula = (formula) => {
        console.log("CREATE:")
        console.log(formula)
        setRows( rows.concat(formula) )
        setShowFormulaDetails( formula );
        setFilterText(formula.formula_name)
        setNewFormula({})
    }

    const onFormulaEdit = (formula) => {
        console.log("ON EDIT DONE")
        console.log(formula)
        const oldRows = rows.filter(r => r.formula_id !== formula.formula_id)
        setRows(oldRows.concat( formula )) // JSON.parse(JSON.stringify(formula))
        setEditFormula({})
        setShowFormulaDetails(formula)
    }

    const deleteFormula = async (formulaId) => {
        let response = await fetchAcu('office/formula/' + formulaId, {method: 'DELETE'});
        if (response.status !== 200) {
            let errorMessage = await response.text()
            props.onError({status: response.status, message: errorMessage})
        } else {
            let okMessage = await response.text();
            console.log("Delete: " + okMessage)
            setRows(rows.filter(row => (row.formula_id !== formulaId)))
        }
    }

    const subHeaderComponentMemo = React.useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setShowFormulaDetails( {} );
                    setFilterText('');
                }
            };
            return (
                <div className={style.formulasTableHeader}>
                    <AddFormulaButton onClick={()=>setNewFormula({new: 1})}>New Formula</AddFormulaButton>
                    <AddFormulaButton onClick={()=>setNewFormula(showFormulaDetails)}
                                      disabled={!showFormulaDetails.formula_id}
                    >Copy Formula</AddFormulaButton>
                    <FilterComponent
                        onFilter={e => setFilterText(e.target.value)}
                        onClear={handleClear}
                        filterText={filterText}
                        onShowClassic={()=>setShowClassic(!showClassic)}
                        showClassic={showClassic}
                    />
                </div>
            );
        },
        [filterText, resetPaginationToggle, showClassic, showFormulaDetails]);

    const conditionalRowStyles = [
        {
            when: row => row.formula_id === showFormulaDetails.formula_id,
            style: {
                backgroundColor: '#ffffcc',
            },
        },
    ];

    const columnsFormula = [
        {
            id: 'name',
            name: 'Name',
            selector: row => (row.formula_name),
            sortable: true,
            sortFunction: caseInsensitiveSort('formula_name'),
            style: {fontSize: '16px'},
            cell: row => (
                <div>{row.formula_name}
                    {( row.is_classic &&
                            <sup>classic</sup>
                    )}
                </div>
            )
        },
        {
            id: 'notes',
            name: 'Notes',
            cell: row => (
                <div className={style.notesCell}>
                    {( row.allergens &&
                        <div className={style.allergens}>{row.allergens}</div>
                    )}
                    <div>{row.notes}</div>
                </div>
            )
        },
        {
            name: '',
            cell: (row) => (<>
                <button onClick={() => { setShowFormulaDetails(row); setEditFormula(row) }}
                        className={style.buttonEditFormula}>&#x270D;</button>
                <button onClick={() => {setShowFormulaDetails(row); setFormulaToDel(row)}}
                        className={style.buttonRemoveFormula}>x</button>
            </>),
            width: '85px',
        },
    ]


    return (
        <>
            {( (newFormula.new || newFormula.formula_id) &&
                <NewFormula template={newFormula}
                            withClassic={props.user.is_internal}
                            onCancel={()=>setNewFormula({})}
                            onFormulaAdd={handleOnCreateFormula}
                            onError={props.onError}
                />
            )}
            {( !(newFormula.new || newFormula.formula_id) &&
                <div className={style.formulasPage}>
                    <div className={style.formulas}>
                        {( formulaToDel.formula_id &&
                            <DeleteDialog
                                onCloseModal={() => setFormulaToDel({})}
                                onClickDelete={() => {deleteFormula(formulaToDel.formula_id); setFormulaToDel({})} }
                                title={(<>{'You’re about to delete formula '}<em>{formulaToDel.formula_name}</em></>)}
                            />
                        )}
                        <DataTable
                            columns={columnsFormula}
                            keyField={'name'}
                            data={filteredItems}
                            progressPending={pending}
                            persistTableHead
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            highlightOnHover={true}
                            dense={true}
                            conditionalRowStyles={conditionalRowStyles}
                            defaultSortFieldId={'name'}
                            pagination
                            paginationPerPage={10}
                            paginationResetDefaultPage={resetPaginationToggle}
                            onRowClicked={(row) => setShowFormulaDetails(row)  }
                        />
                    </div>
                    <div className={style.formulaDetails}>
                        <FormulaDetails key={showFormulaDetails.formula_id}  formulaId={showFormulaDetails.formula_id} onError={props.onError} />
                    </div>
                </div>
            )}
            <div className={style.bottomPad}></div>
            {(
                editFormula.formula_id &&
                    <EditFormula formula={editFormula} onFormulaEdit={onFormulaEdit}
                                 withClassic={props.user.is_internal}
                                 onCancel={() => {setShowFormulaDetails(editFormula);setEditFormula({})}}
                                 onError={props.onError}
                    />
            )}
        </>
    )
}

export default Formulas