import React, {useState, useEffect} from "react";
import styled from "styled-components";
import * as style from "./newFormula.module.scss"
import DataTable from "react-data-table-component";
import Sprintf from "sprintf-js";
import Select from 'react-select';
import {fetchAcu} from "../../fetchacu";
import {caseInsensitiveSort} from "../../data-table-utils"

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;


const NewFormula = (props) => {
    const [formulaName, setFormulaName] = useState('')
    const [notes, setNotes] = useState('')
    const [components, setComponents] = useState(props.template.components ? props.template.components.slice() : [])
    const [selectedComponent, setSelectedComponent] = useState({})
    const [selectedParts, setSelectedParts] = useState(1)
    const [herbs, setHerbs] = useState([])
    const [pending, setPending] = useState(true);
    const [classic, setClassic] = useState(false);
    const [errorName, setErrorName] = useState('')

    const onlyUnique = (value, index, self) => self.indexOf(value) === index;
    const updateAllergens = () => {
        const newAllergens = []
        components.forEach( component => {
            component.allergens.forEach( allergen => newAllergens.push(allergen) )
        })
        return newAllergens.filter(onlyUnique).join(', ');
    }
    const allergens = updateAllergens()

    const herbsColumns = [
        {
            id: 'herbCode',
            name: 'Code',
            selector: row => row.herb_code,
            format: row => {return Sprintf.sprintf('%03d', row.herb_code)},
            width: '60px',
        },
        {
            id: 'herbName',
            name: 'Herb Name',
            selector: row => row.herb_name,
            sortable: true,
            sortFunction: caseInsensitiveSort('herb_name'),
            cell: row => { let ar =  [row.herb_name];
                if (row.common_name) {
                    ar.push('(' + row.common_name+ ')')
                }
                return ar.join(' ');
            },
        },
        {
            name: 'Parts',
            selector: row => row.parts,
//            format: row => ( row.parts === "0.5" ? '½' : (row.parts.match(/\.0$/) ? parseInt(row.parts) : row.parts )),
            format: row => (<input class={style.parts} type={'number'} value={row.parts} step={0.1} onChange={ e => { changeComponent(row.herb_code, e.target.value) } } />),
            width: '100px',
        },
        {
            name: '',
            cell: (row) => (<>
                <input type={'button'} onClick={() => {
                    setComponents( components.filter( item => item.herb_code !== row.herb_code  ) );
                    return false
                }} className={style.buttonRemoveComponent} value={'X'} />
            </>),
            width: '45px',
        }
    ]


    useEffect(()=>{
        fetchAcu('office/herbs')
            .then(
                response => {
                    if ( response.status === 200 )
                        response.json().then(data => {setHerbs(data);setPending(false)})
                    else {
                        response.text().then(
                            errorMessage => {
                                console.log(errorMessage)
                                props.onError({status: response.status, message: errorMessage})
                            }
                        )
                    }
                }
            )
            .catch(err => console.log(err))
    },[props])

    const options = herbs.map(r => ({label: r.herb_name, value: r}));

    const submitHandler = async e => {
        e.preventDefault()
        setErrorName('')
        let formula = {
            formula_name: formulaName,
            notes: notes,
            is_classic: classic,
            components: components.map(i => ({herb_code: i.herb_code, parts: i.parts})),
        }
        let response = await fetchAcu('office/formula', {method: 'POST', body: JSON.stringify(formula) })
        if (response.status === 200) {
            const data = await response.json()
            console.log("Create formula: " + data)
            if(data.formula_id === 0) {
                setErrorName('This name is in use. Please rename.')
            } else {
                formula.allergens = allergens
                formula.components = components
                formula.formula_id = data.formula_id
                props.onFormulaAdd(formula)
            }
        } else {
            const errorMessage = await response.text()
            console.log("Create formula error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    const changeComponent = (herb_code, parts) => {
        setComponents(components.map( i => ( { ...i,  parts: herb_code == i.herb_code ? parts : i.parts }  )  ) )
    }

    return (
        <form onSubmit={submitHandler}>
            <div className={style.newFormulaContainer}>
                <div className={style.newFormulaHeader}>
                    <h2>New Formula</h2>
                </div>
                <div className={style.newFormulaColumns}>
                    <div className={style.newFormulaContent}>
                        <div className={style.newFormulaContentRow}>
                            <div>{"Formula Name:"}</div>
                            <div style={{position:'relative'}}><input type={'text'} defaultValue={''}
                                    required={true}
                                    onChange={e => setFormulaName(e.target.value)} />
                                <div style={{color:'red'}} >{errorName}</div>
                            </div>
                        </div>
                        { props.withClassic && (
                            <div className={style.newFormulaContentRow}>
                                <div>{"Classic:"}</div>
                                <div><input type={'checkbox'} checked={classic}
                                            onChange={e => setClassic(!classic)} /></div>
                            </div>
                        )}
                        <div className={style.newFormulaContentRow}>
                            <div>{"Notes:"}</div>
                            <textarea type={'text'} defaultValue={''}
                                        onChange={e => setNotes(e.target.value)}></textarea>
                        </div>
                        <div className={style.newFormulaFooter}>
                            <SubmitButton value={ "Submit" }/>
                            <Button onClick={() => { props.onCancel(); return false}}>Cancel</Button>
                        </div>
                    </div>
                    <div className={style.newFormulaContent}>
                        { !pending && (
                            <div className={style.addComponentContainer}>
                                <Select className={style.herbSelect}
                                        onChange={(newValue) =>
                                        {
                                            console.log(newValue)
                                            setSelectedComponent(newValue)
                                        }
                                }
                                        options={options}
                                />
                                <input className={style.addPartsInput} type={'number'}
                                       step={0.1}
                                       defaultValue={selectedParts}
                                       onChange={(e)=>setSelectedParts(e.target.value)}
                                />
                                <input type={'button'} className={style.addPartsButton}
                                        onClick={()=>{
                                            console.log(components)
                                            console.log({ ...selectedComponent.value, parts: selectedParts})
                                            const filtered = components.filter(i => i.herb_code !== selectedComponent.value.herb_code)
                                            setComponents(filtered.concat({ ...selectedComponent.value, parts: selectedParts.toString()}));
                                            return false;
                                        }}
                                       value={'Add'}
                                />
                            </div>
                        )}
                        {( allergens &&
                            <div className={style.allergens}>
                                Allergens: {allergens}
                            </div>
                        )}
                        <DataTable
                            columns={herbsColumns}
                            keyField={'herbCode'}
                            data={components}
                            persistTableHead
                            defaultSortFieldId={'herbName'}
                            highlightOnHover={true}
                            dense={true}
                        />
                    </div>
                </div>
                <div className={style.newFormulaFooter}>
                </div>
            </div>
        </form>
        )
}

export default NewFormula
