import React, {useEffect, useState} from "react";
import * as style from "./prescriptionDetails.module.scss"
import styled from "styled-components";
import FormulaDetails from "../../formulaDetails/formulaDetails";
import Select from "react-select";
import {fetchAcu} from "../../fetchacu";

const consultFeeOptions = [{label: '$0', value: 0}, {label: '$15', value: 15}, {label: '$30', value: 30}]

const Button = styled.button`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const SubmitButton = styled.input.attrs({type: 'submit'})`
    border: outset;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
`;

const selectOptions = [
    {label: 'new', value: 'new'},
    {label: 'paid', value: 'paid'},
    // {label: 'packed', value: 'packed'},
    // {label: 'shipped', value: 'shipped'},
    {label: 'completed', value: 'packed/completed'},
]

const PrescriptionDetails = (props) => {
    const [prescription, setPrescription] = useState(props.prescription)
    const [prescriptionChanged, setPrescriptionChanged] = useState(false)
    const [status, setStatus] = useState(prescription.status)
    const [markup, setMarkup] = useState(prescription.markup)
    const [location, setLocation] = useState('')
    const [consultFee, setConsultFee] = useState(prescription.consult_fee || 0)
    const consultFeeOptionSelected = consultFeeOptions.find(o => (parseFloat(o.value) === parseFloat(consultFee)))
    const changed = (status !== prescription.status)
        || ( markup !== prescription.markup )
        || ( consultFee !== prescription.consult_fee )
    const submitHandler = async (e) => {
        e.preventDefault()
        if (!changed)
            return props.onCancel(prescriptionChanged)

        console.log(status)
        // console.log(trackingCode)

        const upd = {}
        if ( status !== prescription.status )
            upd.status = status
        if ( markup !== prescription.markup )
            upd.markup = markup

        if ( consultFee !== prescription.consult_fee )
            upd.consult_fee = consultFee

        let response = await fetchAcu('stock/prescription/' + prescription.prescription_id, {method: 'PUT', body: JSON.stringify(upd)})
        if (response.status === 200) {
            const okMessage = await response.text()
            console.log("Update prescription: " + okMessage)
            upd.status = status
            if (upd.status === 'packed')
                upd.status = 'completed'
            upd.prescription_id = prescription.prescription_id
            props.onPrescriptionUpdate(upd)
        } else {
            const errorMessage = await response.text()
            console.log("Update prescription error: " + errorMessage)
            props.onError({status: response.status, message: errorMessage})
        }
    }

    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         if (event.key === "Escape") {
    //             event.preventDefault();
    //             if (!changed) {
    //                 props.onCancel()
    //             }
    //         }
    //     };
    //     document.addEventListener("keydown", handleKeyDown);
    //     return () => {
    //         document.removeEventListener("keydown", handleKeyDown);
    //     };
    // }, [changed]);

    useEffect(() => {
        // Добавляем начальное состояние в историю
        window.history.pushState(null, "", window.location.href);
        // Обработчик нажатия кнопки "Назад"
        const handlePopState = () => {
            console.log("Переход назад заблокирован");
            // Возвращаем пользователя на текущую страницу
            window.history.pushState(null, "", window.location.href);
            props.onCancel(true);
        };
        // Добавляем обработчик события popstate
        window.addEventListener("popstate", handlePopState);
        // Убираем обработчик при размонтировании компонента
        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const onPrescriptionPack = (prescriptionId) => {
        const prescr = JSON.parse(JSON.stringify(prescription))
        // prescr.status='packed';
        prescr.status='completed';
        console.log("PACKED", prescr)
        props.onPrescriptionUpdate(prescr, true)
    }

    const totalPrice = Math.round( 100.0 * (parseFloat(prescription.formula_cost)
        * parseFloat(prescription.multiplicator)
        * parseFloat(markup)
        + parseFloat(consultFee)
        + parseFloat(prescription.fulfillment_fee)
    )
         ) / 100;

    const makeAmend = async (components) => {
        let response = await fetchAcu('office/amendformula',
            {method: 'POST', body: JSON.stringify({
                    prescription_id: prescription.prescription_id,
                    components: components.reduce((a,e) => {a.push({herb_code: e.herb_code, parts: e.parts}); return a}, [])
                })})
        const retPrescription = (await response.json()).prescription
        const newPrescription = JSON.parse(JSON.stringify(prescription))
        newPrescription.formula_id = retPrescription.formula_id
        newPrescription.formula_name = retPrescription.formula_name
        newPrescription.formula_cost = retPrescription.formula_cost
        newPrescription.formula_weight = retPrescription.formula_weight
        newPrescription.herbs_cost = retPrescription.herbs_cost
        newPrescription.prescription_price = retPrescription.prescription_price
        newPrescription.multiplicator = retPrescription.multiplicator
        console.log(JSON.stringify({prescription}, null, 2))
        console.log(JSON.stringify({newPrescription}, null, 2))
        setPrescription(newPrescription)
        setPrescriptionChanged(true)
        console.log('set ok')
    }

    return (
        <form onSubmit={submitHandler}>
            <div className={style.prescriptionDetailsContainer}>
                <div className={style.prescriptionDetailsHeader}>
                    <h2>Prescription Fulfillment</h2>
                </div>
                <div className={style.prescriptionDetailsColumns}>
                    <div className={style.prescriptionDetailsContentLeft}>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Prescription ID:"}</div>
                                <div>{prescription.prescription_id}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Created:</div>
                                <div>{prescription.date}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Patient:"}</div>
                                <div>{prescription.patient_name}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Herbalist:</div>
                                <div>{prescription.herbalist_name}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Payment:"}</div>
                                <div>{prescription.payment_method === 'local' ? 'Cash' : prescription.payment_method }</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Formula:"}</div>
                                <div>{prescription.formula_name}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Formula grams:</div>
                                <div>{prescription.formula_weight}g</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Total grams:"}</div>
                                <div>{prescription.total_grams}g</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Multiplication Factor:</div>
                                <div>x{prescription.multiplicator}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Herbs cost:"}</div>
                            <div>{ Math.round(100.0 * parseFloat(prescription.formula_cost) * parseFloat(prescription.multiplicator))/100}</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Fulfillment fee:"}</div>
                            <div>{ prescription.fulfillment_fee }</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Markup:"}</div>
                            <div>x<input type={'number'} step={0.1}  min={0.1} defaultValue={prescription.markup}
                                         readOnly={ prescription.status !== "new" }
                                        onChange={e => setMarkup(e.target.value)}
                            /></div>
                        </div>

                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Consult Fee:"}</div>
                            <div><Select className={style.selectStatus}
                                         isSearchable={false}
                                         value={consultFeeOptionSelected}
                                         options={consultFeeOptions}
                                         required={true}
                                         autoFocus={false}
                                         blurInputOnSelect={true}
                                         isSearchable={false}
                                         closeMenuOnSelect={true}
                                         onChange={newValue => setConsultFee(newValue.value)}
                            />
                            </div>
                        </div>


                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Total price:"}</div>
                            <div>{ totalPrice }</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>{"Paid:"}</div>
                                <div>{prescription.paid_at || '-'}</div>
                            </div>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Packed:</div>
                                <div>{prescription.packed_at || '-'}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div className={style.prescriptionDetailsContentRowLeft}>
                                <div>Completed:</div>
                                <div>{prescription.completed_at || '-'}</div>
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Notes:"}</div>
                            <div className={style.paddingLeft}>{prescription.notes}</div>
                        </div>
                        <div className={style.prescriptionDetailsContentRow}>
                            <div>{"Status:"}</div>
                            <div><Select className={style.selectStatus}
                                         defaultValue={ { label: prescription.status, value: prescription.status } }
                                         options={selectOptions}
                                         autoFocus={false}
                                         blurInputOnSelect={true}
                                         isSearchable={false}
                                         closeMenuOnSelect={true}
                                         onChange={newValue => setStatus(newValue.value)}
                            />
                            </div>
                        </div>
                        <div className={style.prescriptionDetailsFooter}>
                            <SubmitButton value={ "Save" }/>
                            <Button onClick={() => { props.onCancel(prescriptionChanged); return false}}>Cancel</Button>
                        </div>
                    </div>
                    <div className={style.prescriptionDetailsContentRight}>
                        <FormulaDetails key={prescription.formula_id}
                                        formulaId={prescription.formula_id}
                                        onError={props.onError}
                                        showPrint={true}
                                        showPack={prescription.status === "paid"}
                                        prescriptionId={prescription.prescription_id}
                                        onPrescriptionPack={onPrescriptionPack}
                                        multiplicator={prescription.multiplicator}
                                        patientName={prescription.patient_name}
                                        notes={prescription.notes}
                                        totalGrams={prescription.total_grams}
                                        date={prescription.date}
                                        location={location}
                                        total_grams={prescription.total_grams}
                                        onChangeLocation={(l) => setLocation(l)}
                                        onAmend={async (newFormula) => { await makeAmend(newFormula) }}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default PrescriptionDetails