// extracted by mini-css-extract-plugin
export var addComponentContainer = "formulaDetails-module--add-component-container--zEK5K";
export var addPartsButton = "formulaDetails-module--add-parts-button--cpRF-";
export var addPartsInput = "formulaDetails-module--add-parts-input--1QVTv";
export var allergens = "formulaDetails-module--allergens--fzDT6";
export var amendButton = "formulaDetails-module--amend-button--HmZOP";
export var amendCancelButton = "formulaDetails-module--amend-cancel-button--a3DrU";
export var amendSaveButton = "formulaDetails-module--amend-save-button--MEO7j";
export var buttonRemoveComponent = "formulaDetails-module--button-remove-component--Q1HnJ";
export var formulaDetails = "formulaDetails-module--formula-details--uKSIh";
export var formulaDetailsHeader = "formulaDetails-module--formula-details-header--RfCjd";
export var formulaDetailsNotes = "formulaDetails-module--formula-details-notes--ERfyR";
export var formulaDetailsTableHeader = "formulaDetails-module--formula-details-table-header--m3--O";
export var herbSelect = "formulaDetails-module--herb-select--D7Ytu";
export var packButton = "formulaDetails-module--pack-button--L-0jk";
export var parts = "formulaDetails-module--parts--aDw55";
export var printButton = "formulaDetails-module--print-button--couqS";
export var radioCell = "formulaDetails-module--radio-cell--ERYFD";